<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="6"
      >
        <b-card
          title="Excel Veri Aktarımı"
        >
          <b-form-group
            label="Dosya Seçin"
            label-for="file"
          >
            <b-form-file
              id="file"
              accept=".xls, .xlsx"
              browse-text="Dosya Seçin"
              placeholder="Dosya Seçin"
              @change="onChange"
            />
          </b-form-group>
          <b-form-group
            label="Çalışma Sayfası Seçiniz"
            label-for="sheets"
          >
            <xlsx-read :file="file">
              <xlsx-sheets>
                <template #default="{sheets}">
                  <v-select
                    id="sheets"
                    v-model="selectedSheet"
                    :options="sheets"
                    placeholder="Seçiniz"
                    :disabled="!file"
                  />
                </template>
              </xlsx-sheets>
            </xlsx-read>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col cols="6">
        <information />
      </b-col>
      <b-col
        v-if="saveStatus.status !== null"
        cols="12"
      >
        <b-card
          title="Aktarım Sonucu"
          class="text-center"
        >
          <b-alert
            show
            :variant="saveStatus.status? 'success' : 'danger'"
          >
            <div class="alert-body">
              <div class="mt-1">
                <FeatherIcon
                  :icon="saveStatus.status? 'CheckCircleIcon' : 'XCircleIcon'"
                  size="36"
                />
              </div>
              <div>
                {{ saveStatus.message }}
              </div>
              <div
                v-if="saveStatus.status"
                class="mt-1"
              >
                <b-button
                  variant="success"
                  :to="'/stocks'"
                >
                  Listeyi Görüntüle
                </b-button>
              </div>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="selectedSheet"
        cols="12"
      >
        <b-card
          title="Dosya Önizleme"
        >
          <div class="height-500 overflow-scroll">
            <div
              v-if="!tempData"
              class="text-center"
            >
              <b-spinner />
            </div>
            <b-table
              striped
              small
              hover
              :items="tempData"
              class="font-small-1"
            />
          </div>
          <xlsx-read
            :file="file"
            class="hidden"
          >
            <xlsx-table
              :sheet="selectedSheet"
            />
            <xlsx-json :sheet="selectedSheet">
              <template #default="{collection}">
                <div>
                  {{ collection }}
                  {{ getData(collection) }}
                </div>
              </template>
            </xlsx-json>
          </xlsx-read>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-button
          variant="danger"
          :disabled="!selectedSheet"
          @click="importDataSave"
        >
          <FeatherIcon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BFormFile, BFormGroup, BRow, BCol, BTable, BButton, BAlert, BSpinner,
} from 'bootstrap-vue'
import {
  XlsxRead, XlsxTable, XlsxSheets, XlsxJson,
} from 'vue-xlsx'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import Information from '@/views/Sales/Stocks/AllocateStocks/Information'

export default {
  name: 'Index',
  components: {
    BCard,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BButton,
    BAlert,
    BSpinner,
    Information,
    vSelect,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
  },
  data() {
    return {
      submitStatus: false,
      tempData: null,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['stock/getStockSaveStatus']
    },
  },
  created() {
    this.$store.commit('stock/SET_STOCK_SAVE', {
      message: null,
      status: null,
      id: null,
    })
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null
    },
    getData(colection) {
      this.tempData = colection
    },
    importDataSave() {
      this.submitStatus = true
      this.$store.dispatch('stock/allocateStocksInsert', {
        importData: this.tempData,
      })
    },
  },
}
</script>
