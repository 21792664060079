<template>
  <b-card class="bg-primary text-center text-light match-height d-flex align-middle">
    <div>
      <div class="font-weight-bolder font-medium-4">
        Bilgilendirme
      </div>
      <div class="font-weight-light">
        Yükleme yapmadan önce örnek şablon dosyasını kontrol edin.
      </div>
      <div class="font-weight-light">
        İlk defa yükleme yapıyorsanız <strong>aktarım klavuzunu</strong> inceleyin.
      </div>
      <div class="mt-1">
        <b-button
          variant="info"
          size="lg"
          class="btn-block"
          :href="baseURL + '/media/templates/tahsis-stok-ekleme-sablonu.xlsx'"
          target="_blank"
        >
          <FeatherIcon
            icon="DownloadIcon"
            size="24"
          />
          Örnek Şablon
        </b-button>
      </div>
      <div class="mt-1">
        <b-button
          variant="success"
          class="btn-block"
          :href="baseURL + '/media/guide/tahsis-stok-aktarim-klavuzu.pdf'"
          target="_blank"
        >
          <FeatherIcon icon="DownloadIcon" />
          Aktarım Klavuzu
        </b-button>
      </div>
      <div>
        <b-button
          v-b-modal.modal-brands
          variant="secondary"
          class="mt-1 btn-block"
        >
          <FeatherIcon icon="EyeIcon" />
          Marka Listesi
        </b-button>
      </div>
    </div>
    <b-modal
      id="modal-brands"
      title="Marka Listesi"
      ok-only
      centered
      size="lg"
      ok-title="Kapat"
    >
      <b-table
        striped
        hover
        :fields="fields"
        :items="brands"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BTable,
} from 'bootstrap-vue'

export default {
  name: 'Information',
  components: {
    BCard,
    BButton,
    BModal,
    BTable,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      fields: [
        {
          key: 'id',
          label: 'NO',
          thStyle: { width: '50px' },
        },
        {
          key: 'name',
          label: 'MARKA',
        },
      ],
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS name'],
        where: {
          'com_brand.salestatus': 1,
        },
        order_by: ['com_brand.name', 'ASC'],
      })
    },
  },
}
</script>

<style scoped>

</style>
